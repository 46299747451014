import React from 'react'
import PropTypes from 'prop-types'

export const Email = ({ width, height }) => (
    <svg width={width || '24'} height={height || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0.0200195C5.373 0.0200195 0 5.39302 0 12.02C0 18.647 5.373 24.02 12 24.02C18.627 24.02 24 18.647 24 12.02C24 5.39302 18.627 0.0200195 12 0.0200195V0.0200195ZM18.99 7.00002L12 12.666L5.009 7.00002H18.99ZM19 17H5V8.49502L12 14.168L19 8.49602V17Z" fill="#000000" />
    </svg>
)

Email.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
}
import React from 'react'
import PropTypes from 'prop-types'

export const Developer = ({ width, height }) => (
    <svg width={width || '339'} height={height || '48'} viewBox="0 0 339 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.0500488 37V10.8H6.75005V37H0.0500488ZM0.0500488 7.15V0.5H6.75005V7.15H0.0500488Z" fill="#E78E76" />
        <path d="M28.5942 37.25C25.9942 37.25 23.6275 36.75 21.4942 35.75C19.3942 34.75 17.5775 33.4 16.0442 31.7C14.5442 29.9667 13.3775 28.0333 12.5442 25.9C11.7109 23.7333 11.2942 21.5167 11.2942 19.25C11.2942 16.8833 11.7275 14.6333 12.5942 12.5C13.4942 10.3333 14.7109 8.41667 16.2442 6.75C17.8109 5.05 19.6442 3.71667 21.7442 2.75C23.8775 1.75 26.2109 1.25 28.7442 1.25C31.3109 1.25 33.6442 1.76667 35.7442 2.8C37.8775 3.83333 39.6942 5.21667 41.1942 6.95C42.6942 8.68333 43.8609 10.6167 44.6942 12.75C45.5275 14.8833 45.9442 17.0833 45.9442 19.35C45.9442 21.6833 45.5109 23.9333 44.6442 26.1C43.7775 28.2333 42.5609 30.15 40.9942 31.85C39.4609 33.5167 37.6275 34.8333 35.4942 35.8C33.3942 36.7667 31.0942 37.25 28.5942 37.25ZM18.2942 19.25C18.2942 20.7833 18.5275 22.2667 18.9942 23.7C19.4609 25.1333 20.1275 26.4167 20.9942 27.55C21.8942 28.65 22.9775 29.5333 24.2442 30.2C25.5442 30.8333 27.0109 31.15 28.6442 31.15C30.3109 31.15 31.7942 30.8167 33.0942 30.15C34.3942 29.45 35.4775 28.5333 36.3442 27.4C37.2109 26.2333 37.8609 24.95 38.2942 23.55C38.7609 22.1167 38.9942 20.6833 38.9942 19.25C38.9942 17.7167 38.7442 16.25 38.2442 14.85C37.7775 13.4167 37.0942 12.15 36.1942 11.05C35.3275 9.91667 34.2442 9.03333 32.9442 8.4C31.6775 7.73333 30.2442 7.4 28.6442 7.4C26.9442 7.4 25.4442 7.75 24.1442 8.45C22.8775 9.11667 21.8109 10.0167 20.9442 11.15C20.0775 12.2833 19.4109 13.55 18.9442 14.95C18.5109 16.35 18.2942 17.7833 18.2942 19.25Z" fill="#E78E76" />
        <path d="M72.3872 10.8C72.1538 10.5667 71.7205 10.25 71.0872 9.85C70.4872 9.45 69.7372 9.06667 68.8372 8.7C67.9705 8.33333 67.0205 8.01667 65.9872 7.75C64.9538 7.45 63.9038 7.3 62.8372 7.3C60.9705 7.3 59.5538 7.65 58.5872 8.35C57.6538 9.05 57.1872 10.0333 57.1872 11.3C57.1872 12.2667 57.4872 13.0333 58.0872 13.6C58.6872 14.1667 59.5872 14.65 60.7872 15.05C61.9872 15.45 63.4872 15.8833 65.2872 16.35C67.6205 16.9167 69.6372 17.6167 71.3372 18.45C73.0705 19.25 74.3872 20.3167 75.2872 21.65C76.2205 22.95 76.6872 24.6833 76.6872 26.85C76.6872 28.75 76.3372 30.3833 75.6372 31.75C74.9372 33.0833 73.9705 34.1667 72.7372 35C71.5038 35.8333 70.1038 36.45 68.5372 36.85C66.9705 37.2167 65.3038 37.4 63.5372 37.4C61.7705 37.4 60.0038 37.2167 58.2372 36.85C56.4705 36.4833 54.7705 35.9667 53.1372 35.3C51.5038 34.6 50.0038 33.7667 48.6372 32.8L51.6872 26.85C51.9872 27.15 52.5205 27.55 53.2872 28.05C54.0538 28.5167 54.9872 29 56.0872 29.5C57.1872 29.9667 58.3872 30.3667 59.6872 30.7C60.9872 31.0333 62.3038 31.2 63.6372 31.2C65.5038 31.2 66.9205 30.8833 67.8872 30.25C68.8538 29.6167 69.3372 28.7167 69.3372 27.55C69.3372 26.4833 68.9538 25.65 68.1872 25.05C67.4205 24.45 66.3538 23.9333 64.9872 23.5C63.6205 23.0333 62.0038 22.5333 60.1372 22C57.9038 21.3667 56.0372 20.6667 54.5372 19.9C53.0372 19.1 51.9205 18.1 51.1872 16.9C50.4538 15.7 50.0872 14.2 50.0872 12.4C50.0872 9.96667 50.6538 7.93333 51.7872 6.3C52.9538 4.63333 54.5205 3.38333 56.4872 2.55C58.4538 1.68333 60.6372 1.25 63.0372 1.25C64.7038 1.25 66.2705 1.43333 67.7372 1.8C69.2372 2.16667 70.6372 2.65 71.9372 3.25C73.2372 3.85 74.4038 4.5 75.4372 5.2L72.3872 10.8Z" fill="#E78E76" />
        <path d="M93.9129 37V1.5H106.813C110.68 1.5 113.913 2.3 116.513 3.9C119.113 5.46667 121.063 7.6 122.363 10.3C123.663 12.9667 124.313 15.9333 124.313 19.2C124.313 22.8 123.596 25.9333 122.163 28.6C120.73 31.2667 118.696 33.3333 116.063 34.8C113.463 36.2667 110.38 37 106.813 37H93.9129ZM117.363 19.2C117.363 16.9 116.946 14.8833 116.113 13.15C115.28 11.3833 114.08 10.0167 112.513 9.05C110.946 8.05 109.046 7.55 106.813 7.55H100.813V30.95H106.813C109.08 30.95 110.996 30.45 112.563 29.45C114.13 28.4167 115.313 27.0167 116.113 25.25C116.946 23.45 117.363 21.4333 117.363 19.2Z" fill="#E78E76" />
        <path d="M141.204 37.5C139.104 37.5 137.204 37.15 135.504 36.45C133.804 35.7167 132.354 34.7333 131.154 33.5C129.954 32.2333 129.021 30.8 128.354 29.2C127.721 27.5667 127.404 25.85 127.404 24.05C127.404 21.55 127.954 19.2667 129.054 17.2C130.187 15.1333 131.787 13.4833 133.854 12.25C135.954 10.9833 138.421 10.35 141.254 10.35C144.121 10.35 146.571 10.9833 148.604 12.25C150.637 13.4833 152.187 15.1333 153.254 17.2C154.354 19.2333 154.904 21.4333 154.904 23.8C154.904 24.2 154.887 24.6167 154.854 25.05C154.821 25.45 154.787 25.7833 154.754 26.05H134.504C134.637 27.3833 135.021 28.55 135.654 29.55C136.321 30.55 137.154 31.3167 138.154 31.85C139.187 32.35 140.287 32.6 141.454 32.6C142.787 32.6 144.037 32.2833 145.204 31.65C146.404 30.9833 147.221 30.1167 147.654 29.05L153.404 30.65C152.771 31.9833 151.854 33.1667 150.654 34.2C149.487 35.2333 148.104 36.05 146.504 36.65C144.904 37.2167 143.137 37.5 141.204 37.5ZM134.354 21.8H148.054C147.921 20.4667 147.537 19.3167 146.904 18.35C146.304 17.35 145.504 16.5833 144.504 16.05C143.504 15.4833 142.387 15.2 141.154 15.2C139.954 15.2 138.854 15.4833 137.854 16.05C136.887 16.5833 136.087 17.35 135.454 18.35C134.854 19.3167 134.487 20.4667 134.354 21.8Z" fill="#E78E76" />
        <path d="M165.352 37L155.702 10.8H162.652L169.252 30.9L175.902 10.8H182.252L172.652 37H165.352Z" fill="#E78E76" />
        <path d="M197.063 37.5C194.963 37.5 193.063 37.15 191.363 36.45C189.663 35.7167 188.213 34.7333 187.013 33.5C185.813 32.2333 184.88 30.8 184.213 29.2C183.58 27.5667 183.263 25.85 183.263 24.05C183.263 21.55 183.813 19.2667 184.913 17.2C186.047 15.1333 187.647 13.4833 189.713 12.25C191.813 10.9833 194.28 10.35 197.113 10.35C199.98 10.35 202.43 10.9833 204.463 12.25C206.497 13.4833 208.047 15.1333 209.113 17.2C210.213 19.2333 210.763 21.4333 210.763 23.8C210.763 24.2 210.747 24.6167 210.713 25.05C210.68 25.45 210.647 25.7833 210.613 26.05H190.363C190.497 27.3833 190.88 28.55 191.513 29.55C192.18 30.55 193.013 31.3167 194.013 31.85C195.047 32.35 196.147 32.6 197.313 32.6C198.647 32.6 199.897 32.2833 201.063 31.65C202.263 30.9833 203.08 30.1167 203.513 29.05L209.263 30.65C208.63 31.9833 207.713 33.1667 206.513 34.2C205.347 35.2333 203.963 36.05 202.363 36.65C200.763 37.2167 198.997 37.5 197.063 37.5ZM190.213 21.8H203.913C203.78 20.4667 203.397 19.3167 202.763 18.35C202.163 17.35 201.363 16.5833 200.363 16.05C199.363 15.4833 198.247 15.2 197.013 15.2C195.813 15.2 194.713 15.4833 193.713 16.05C192.747 16.5833 191.947 17.35 191.313 18.35C190.713 19.3167 190.347 20.4667 190.213 21.8Z" fill="#E78E76" />
        <path d="M214.795 0.5H221.495V28.6C221.495 29.8 221.695 30.6167 222.095 31.05C222.495 31.45 223.045 31.65 223.745 31.65C224.278 31.65 224.828 31.5833 225.395 31.45C225.962 31.3167 226.462 31.15 226.895 30.95L227.795 36.05C226.895 36.4833 225.845 36.8167 224.645 37.05C223.445 37.2833 222.362 37.4 221.395 37.4C219.295 37.4 217.662 36.85 216.495 35.75C215.362 34.6167 214.795 33.0167 214.795 30.95V0.5Z" fill="#E78E76" />
        <path d="M242.814 37.5C240.681 37.5 238.764 37.15 237.064 36.45C235.364 35.7167 233.914 34.7167 232.714 33.45C231.547 32.1833 230.647 30.7333 230.014 29.1C229.381 27.4667 229.064 25.75 229.064 23.95C229.064 22.1167 229.381 20.3833 230.014 18.75C230.647 17.1167 231.547 15.6667 232.714 14.4C233.914 13.1333 235.364 12.15 237.064 11.45C238.764 10.7167 240.681 10.35 242.814 10.35C244.947 10.35 246.847 10.7167 248.514 11.45C250.214 12.15 251.664 13.1333 252.864 14.4C254.064 15.6667 254.964 17.1167 255.564 18.75C256.197 20.3833 256.514 22.1167 256.514 23.95C256.514 25.75 256.197 27.4667 255.564 29.1C254.964 30.7333 254.064 32.1833 252.864 33.45C251.697 34.7167 250.264 35.7167 248.564 36.45C246.864 37.15 244.947 37.5 242.814 37.5ZM235.964 23.95C235.964 25.4833 236.264 26.85 236.864 28.05C237.464 29.2167 238.281 30.1333 239.314 30.8C240.347 31.4667 241.514 31.8 242.814 31.8C244.081 31.8 245.231 31.4667 246.264 30.8C247.297 30.1 248.114 29.1667 248.714 28C249.347 26.8 249.664 25.4333 249.664 23.9C249.664 22.4 249.347 21.05 248.714 19.85C248.114 18.65 247.297 17.7167 246.264 17.05C245.231 16.3833 244.081 16.05 242.814 16.05C241.514 16.05 240.347 16.4 239.314 17.1C238.281 17.7667 237.464 18.7 236.864 19.9C236.264 21.0667 235.964 22.4167 235.964 23.95Z" fill="#E78E76" />
        <path d="M276.736 37.5C274.703 37.5 272.903 37.05 271.336 36.15C269.803 35.2167 268.603 33.9667 267.736 32.4V47.65H261.036V10.8H266.886V15.3C267.886 13.7667 269.153 12.5667 270.686 11.7C272.22 10.8 273.97 10.35 275.936 10.35C277.703 10.35 279.32 10.7 280.786 11.4C282.286 12.1 283.586 13.0833 284.686 14.35C285.786 15.5833 286.636 17.0167 287.236 18.65C287.87 20.25 288.186 21.9833 288.186 23.85C288.186 26.3833 287.686 28.6833 286.686 30.75C285.72 32.8167 284.37 34.4667 282.636 35.7C280.936 36.9 278.97 37.5 276.736 37.5ZM274.486 31.8C275.52 31.8 276.453 31.5833 277.286 31.15C278.12 30.7167 278.836 30.1333 279.436 29.4C280.07 28.6333 280.536 27.7833 280.836 26.85C281.17 25.8833 281.336 24.8833 281.336 23.85C281.336 22.75 281.153 21.7333 280.786 20.8C280.453 19.8667 279.953 19.05 279.286 18.35C278.62 17.6167 277.836 17.05 276.936 16.65C276.07 16.25 275.12 16.05 274.086 16.05C273.453 16.05 272.803 16.1667 272.136 16.4C271.503 16.6 270.886 16.9 270.286 17.3C269.686 17.7 269.153 18.1667 268.686 18.7C268.253 19.2333 267.936 19.8167 267.736 20.45V26.6C268.136 27.5667 268.67 28.45 269.336 29.25C270.036 30.05 270.836 30.6833 271.736 31.15C272.636 31.5833 273.553 31.8 274.486 31.8Z" fill="#E78E76" />
        <path d="M304.876 37.5C302.776 37.5 300.876 37.15 299.176 36.45C297.476 35.7167 296.026 34.7333 294.826 33.5C293.626 32.2333 292.692 30.8 292.026 29.2C291.392 27.5667 291.076 25.85 291.076 24.05C291.076 21.55 291.626 19.2667 292.726 17.2C293.859 15.1333 295.459 13.4833 297.526 12.25C299.626 10.9833 302.093 10.35 304.926 10.35C307.793 10.35 310.242 10.9833 312.276 12.25C314.309 13.4833 315.859 15.1333 316.926 17.2C318.026 19.2333 318.576 21.4333 318.576 23.8C318.576 24.2 318.559 24.6167 318.526 25.05C318.492 25.45 318.459 25.7833 318.426 26.05H298.176C298.309 27.3833 298.693 28.55 299.326 29.55C299.993 30.55 300.826 31.3167 301.826 31.85C302.859 32.35 303.959 32.6 305.126 32.6C306.459 32.6 307.709 32.2833 308.876 31.65C310.076 30.9833 310.893 30.1167 311.326 29.05L317.076 30.65C316.443 31.9833 315.526 33.1667 314.326 34.2C313.159 35.2333 311.776 36.05 310.176 36.65C308.576 37.2167 306.809 37.5 304.876 37.5ZM298.026 21.8H311.726C311.592 20.4667 311.209 19.3167 310.576 18.35C309.976 17.35 309.176 16.5833 308.176 16.05C307.176 15.4833 306.059 15.2 304.826 15.2C303.626 15.2 302.526 15.4833 301.526 16.05C300.559 16.5833 299.759 17.35 299.126 18.35C298.526 19.3167 298.159 20.4667 298.026 21.8Z" fill="#E78E76" />
        <path d="M338.257 16.6C336.224 16.6 334.407 17 332.807 17.8C331.207 18.5667 330.057 19.6833 329.357 21.15V37H322.657V10.8H328.807V16.4C329.741 14.6 330.924 13.1833 332.357 12.15C333.791 11.1167 335.307 10.55 336.907 10.45C337.274 10.45 337.557 10.45 337.757 10.45C337.957 10.45 338.124 10.4667 338.257 10.5V16.6Z" fill="#E78E76" />
    </svg>
)

Developer.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
}
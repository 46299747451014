import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Desktop, colors, maxWidth, Mobile, Backdrop } from '../styles/global'
import { Logo } from '../assets/Logo.js'
import { Developer } from '../assets/Developer'
import { Menu } from '../assets/Menu'
import { Close } from '../assets/Close'
import { addAnalytics } from '../api'
import { linkedin, role, name, routes } from '../constants'

export const navHeightNum = 60
export const navHeight = `${navHeightNum}px`

// styles
const NavContainer = styled.div`
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    padding: 0 10vw;
    width: 100%;
    height: calc(${navHeight} + 40px);
    background-color: ${colors.bgColor};
    z-index: 3;
    transition: height 0.5s ease-in-out;
    border-bottom: none;

    ${props => props.show && css`
        opacity: 1;
    `}

    ${props => props.didScroll && css`
        height: ${navHeight};
        border-bottom: 1px solid #e4e4e4;
    `}

    @media only screen and (max-width: ${maxWidth}) {
        height: ${navHeight};
        border-bottom: 1px solid #e4e4e4;
        padding: 0 5vw;
    }
`

const NavSpacer = styled.div`
    height: calc(${navHeight} + 20px);
    width: 100%;

    ${props => props.didScroll && css`
        height: ${navHeight};
    `}

    @media only screen and (max-width: ${maxWidth}) {
        height: ${navHeight};
    }
`

const LogoContainer = styled(Link)`
    margin-right: auto;
    cursor: pointer;
    :hover {
        opacity: 0.8;
    }
`

const LogoWrapper = styled.div`
    margin-top: 5px;
    width: 220px;
    transition: width 0.2s ease-in-out;

    ${props => props.didScroll && css`
        margin-top: 0px;
        width: 180px;
    `}

    @media only screen and (max-width: ${maxWidth}) {
        margin-top: 0px;
        width: 130px;
    }
`

const DeveloperWrapper = styled.div`
    width: 130px;
    margin-top: 2px;
    transition: 0.2s ease-in-out;

    ${props => props.didScroll && css`
        margin-top: -2px;
        width: 100px;
    `}

    @media only screen and (max-width: ${maxWidth}) {
        margin-top: -8px;
        width: 100px;
    }
`

const mobileMenuHeight = 170
const mobileMarginBottom = 25

const StyledNavLink = styled(Link)`
    margin-left: 15px;
    text-decoration: none;
    color: ${colors.textColor};
    font-size: 15px;
    font-weight: 500;

    :hover {
        color: darkgray;
    }

    @media only screen and (max-width: ${maxWidth}) {
        margin-left: 0;
        margin-bottom: ${mobileMarginBottom}px;
        font-size: 21px;
    }
`

const MobileMenu = styled.div`
    transition: 0.3s ease-in-out;
    position: fixed;
    height: ${mobileMenuHeight}px;
    width: 100vw;
    background: ${colors.bgColor};
    top: -${mobileMenuHeight}px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: ${mobileMarginBottom}px;

    ${props => props.show && css`
        top: ${navHeight};
        border-bottom: 1px solid #e4e4e4;
    `}
`

const MobileMenuContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    cursor: pointer;
`

// prop types
Nav.propTypes = {
    closeProject: PropTypes.func,
}

// component
function Nav({ closeProject }) {
    const [didScroll, setDidScroll] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const location = useLocation()

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleScroll = () => {
        if (window.scrollY > 40) {
            setDidScroll(true)
        } else {
            setDidScroll(false)
        }
    }

    const renderLinks = () => {
        return (
            <>
                <StyledNavLink
                    to={routes.projects}
                    // active style
                    style={(location.pathname === routes.projects) || (location.pathname === '/')
                        ? { color: 'black' }
                        : {}}
                    onClick={() => {
                        closeProject(true, true)
                        setMenuOpen(false)
                        window.scrollTo(0, 0)
                    }}>
                    {'Projects'}
                </StyledNavLink>
                <StyledNavLink
                    onClick={() => {
                        setMenuOpen(false)
                        addAnalytics('resume_clicked')
                    }}
                    to={{ pathname: linkedin }} target='_blank'>
                    {'Resume'}
                </StyledNavLink>
            </>
        )
    }

    return (
        <>
            <NavContainer
                didScroll={didScroll}
                onClick={() => {
                    if (menuOpen) {
                        setMenuOpen(false)
                    }
                }}>
                <LogoContainer
                    to={'/'}
                    onClick={() => {
                        closeProject(true, true)
                        window.scrollTo(0, 0)
                    }}>
                    <LogoWrapper
                        didScroll={didScroll}>
                        <Logo width={'100%'} height={'100%'} alt={name} />
                    </LogoWrapper>
                    <DeveloperWrapper
                        didScroll={didScroll}>
                        <Developer width={'100%'} height={'100%'} alt={role} />
                    </DeveloperWrapper>
                </LogoContainer>
                <Desktop>
                    {renderLinks()}
                </Desktop>
                <Mobile>
                    <MobileMenuContainer onClick={() => setMenuOpen(!menuOpen)}>
                        {menuOpen
                            ? <Close width={'20'} />
                            : <Menu width={'30'} />
                        }
                    </MobileMenuContainer>
                </Mobile>
            </NavContainer>
            <NavSpacer />
            <Mobile>
                <MobileMenu show={menuOpen}>
                    {renderLinks()}
                </MobileMenu>
            </Mobile>
            {menuOpen && <Backdrop onClick={() => setMenuOpen(false)} />}
        </>
    )
}

export default Nav
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Mobile } from '../../../styles/global'
import ProjectContent from './ProjectContent'
import { Chevron } from '../../../assets/Chevron'
import { H1, ProjectTitle, StyledImage } from './Phone'
import ProjectInfo from './ProjectInfo'

const animationTiming = '0.4s'

const MobilePhoneContainer = styled.div`
    position: relative;
    display: flex;
    cursor: pointer;
    margin-bottom: 2px;
    align-items: center;
    justify-content: center;
    padding: 30px 2px;
    overflow-x: hidden;

    ${props => props.bgColor && css`
        background: ${props.bgColor};
    `}
`

const rotation = 90

const MobileActionContainer = styled.div`
    position: absolute;
    bottom: 30px;
    right: 30px;
    transition: ${animationTiming} ease-in-out;
    cursor: pointer;
    ${props => props.$rotate && css`
        -ms-transform: rotate(${rotation}deg);
        -webkit-transform: rotate(${rotation}deg);
        -moz-transform: rotate(${rotation}deg);
        -o-transform: rotate(${rotation}deg)};
        transform: rotate(${rotation}deg);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    `}
`

const BlurbWrapper = styled.h2`
    font-weight: 300;
    font-size: 25px;
    line-height: 1.2;
    margin-top: 5px;
`

const ContentContainer = styled.div`
    transition: ${animationTiming} ease-in-out;
    min-height: 0px;
    opacity: 0;
    ${props => props.willClose && css`
        min-height: 200vh;
    `}
    ${props => props.willOpen && css`
        opacity: 1;
    `}
`

const ContentWrapper = styled.div`
    display: none;
    ${props => (props.show) && css`
        display: block;
    `}
`

const HeaderInfo = styled.div`
    margin-left: 5vw;
    width: 36vw;
    opacity: 1;
    transition: opacity ${animationTiming} ease-in-out;
    ${props => props.show && css`
        transition: ${animationTiming}s ease-in-out;
        opacity: 0;
    `}
    ${props => (props.willOpen) && css`
        transition: ${animationTiming} ease-in-out;
        width: 0px;
        margin-left: 0;
    `}
    ${props => (props.willClose) && css`
        transition: ${animationTiming} ease-in-out;
        opacity: 0;
    `}
`

// prop types
PhoneMobile.propTypes = {
    close: PropTypes.bool,
    closeProject: PropTypes.func,
    getSrc: PropTypes.func,
    index: PropTypes.number.isRequired,
    isMobile: PropTypes.bool.isRequired,
    project: PropTypes.object.isRequired,
    projects: PropTypes.array,
    refs: PropTypes.any,
    selectedIndex: PropTypes.number,
    selectPhone: PropTypes.func,
}

// component
function PhoneMobile({
    close,
    closeProject,
    getSrc,
    index,
    isMobile,
    project,
    projects,
    refs,
    selectedIndex,
    selectPhone,
}) {
    const [showContent, setShowContent] = useState(false)
    const [animateDelayClose, setAnimateDelayClose] = useState(false)
    const [animateDelayOpen, setAnimateDelayOpen] = useState(false)
    const [blockSelect, setBlockSelect] = useState(false)
    const [didLoadImage, setDidLoadImage] = useState(false)

    useEffect(() => {
        if (isMobile && !close && selectedIndex === index) {
            setShowContent(true)
            setAnimateDelayOpen(true)
        }
    }, [isMobile, selectedIndex])

    useEffect(() => {
        if (isMobile && close && showContent) {
            setShowContent(false)
            setAnimateDelayOpen(false)
        }
    }, [close])

    const phoneSelected = () => {
        if (!blockSelect) {
            setBlockSelect(true)
            if (showContent) {
                closeProject()
                setShowContent(false)
                setAnimateDelayOpen(false)
                setAnimateDelayClose(true)
                setTimeout(() => {
                    setAnimateDelayClose(false)
                    setBlockSelect(false)
                }, 400)
            } else {
                selectPhone()
                setShowContent(true)
                setAnimateDelayOpen(false)
                setTimeout(() => {
                    setAnimateDelayOpen(true)
                    setBlockSelect(false)
                }, 400)
            }
        }
    }

    return (
        <Mobile>
            <MobilePhoneContainer
                ref={refs[project._id]}
                bgColor={project.bgColor}
                showContent={showContent}
                onClick={phoneSelected}>
                <StyledImage
                    $bgColor={project.bgColor}
                    $loading={!didLoadImage}
                    onLoad={() => {
                        setTimeout(() => setDidLoadImage(true), 500)
                    }}
                    src={getSrc(didLoadImage, showContent && !animateDelayClose)} />
                <HeaderInfo
                    show={showContent}
                    willOpen={animateDelayOpen}
                    willClose={animateDelayClose}>
                    <div style={{ minWidth: '36vw' }}>
                        <ProjectTitle header={true}>
                            <H1 header={true}>
                                {project.title.toUpperCase()}
                            </H1>
                        </ProjectTitle>
                        <BlurbWrapper>
                            {project.blurb}
                        </BlurbWrapper>
                    </div>
                </HeaderInfo>
                <MobileActionContainer
                    $rotate={showContent}>
                    <Chevron width={'30'} height={'30'} />
                </MobileActionContainer>
            </MobilePhoneContainer>
            <div style={{ background: 'white' }}>
                <ContentContainer
                    willOpen={animateDelayOpen}
                    willClose={animateDelayClose} >
                    <ContentWrapper
                        show={showContent || animateDelayClose}>
                        <ProjectInfo project={project} />
                        <ProjectContent
                            closeProject={phoneSelected}
                            projects={projects}
                            selectedIndex={index} />
                    </ContentWrapper>
                </ContentContainer>
            </div>
        </Mobile>
    )
}

export default PhoneMobile
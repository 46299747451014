import React from 'react'
import PropTypes from 'prop-types'

export const Menu = ({ width, height }) => (
    <svg width={width || '50'} height={height || '26'} viewBox="0 0 50 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.05664 4.6084H47.7598C48.7852 4.6084 49.6152 3.80273 49.6152 2.77734C49.6152 1.75195 48.7852 0.921875 47.7598 0.921875H2.05664C1.03125 0.921875 0.225586 1.75195 0.225586 2.77734C0.225586 3.80273 1.03125 4.6084 2.05664 4.6084ZM2.05664 15.2529H47.7598C48.7852 15.2529 49.6152 14.4473 49.6152 13.4219C49.6152 12.3721 48.7852 11.542 47.7598 11.542H2.05664C1.03125 11.542 0.225586 12.3721 0.225586 13.4219C0.225586 14.4473 1.03125 15.2529 2.05664 15.2529ZM2.05664 25.9219H47.7598C48.7852 25.9219 49.6152 25.0918 49.6152 24.042C49.6152 23.0166 48.7852 22.2109 47.7598 22.2109H2.05664C1.03125 22.2109 0.225586 23.0166 0.225586 24.042C0.225586 25.0918 1.03125 25.9219 2.05664 25.9219Z" fill="#000000"/>
    </svg>
)

Menu.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
}
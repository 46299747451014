import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Image } from 'react-bootstrap'

import {
    colors,
    maxWidth,
    Desktop,
} from '../../../styles/global'
import { Down } from '../../../assets/Down'
import { AppStore } from '../../../assets/AppStore'

// styles
const InfoContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 2vw;
    height: 100%;
    @media only screen and (max-width: 1300px) {
        margin-left: 50px;
    }
    @media only screen and (max-width: ${maxWidth}) {
        margin: 0;
        padding: 25px;
        align-items: flex-start;
        background-color: white;
    }
`

const DownArrowContainer = styled.div`
    cursor: pointer;
    animation: bounce 1s infinite alternate;
    -webkit-animation: bounce 1s infinite alternate;
    position: absolute;
    bottom: 0;
    
    @keyframes bounce {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(-5px);
        }
        }
        @-webkit-keyframes bounce {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(-5px);
        }
    }
`

const ProjectTitleWrapper = styled.h1`
    font-weight: 900;
    font-size: 30px;
    color: black;
    @media only screen and (max-width: ${maxWidth}) {
        font-size: 28px;
    }
`

const ProjectDescWrapper = styled.h2`
    font-weight: 400;
    font-size: 22px;
    width: 100%;
    max-width: 40vw;
    margin-top: 10px;
    color: ${colors.textColor};
    @media only screen and (max-width: ${maxWidth}) {
        max-width: 100%;
        font-size: 21px;
    }
`

const AppIconContainer = styled(Image)`
    width: 85px;
    margin-bottom: 20px;
    border-radius: 20px;
`

const AppStoreContainer = styled.div`
    margin-top: 35px;
    @media only screen and (max-width: ${maxWidth}) {
        margin-top: 25px;
    }
`

const CominSoonContainer = styled.h3`
    font-weight: 600;
    font-size: 15px;
    margin-top: 20px;
`

// prop types
ProjectInfo.propTypes = {
    project: PropTypes.object,
    scrollToContent: PropTypes.func,
}

// component
function ProjectInfo({
    project,
    scrollToContent,
}) {
    return (
        <InfoContainer>
            <Desktop>
                {project.appIcon &&
                    <AppIconContainer src={project.appIcon} />}
            </Desktop>
            <ProjectTitleWrapper>
                {project.title.toUpperCase()}
            </ProjectTitleWrapper>
            <ProjectDescWrapper>
                {project.description}
            </ProjectDescWrapper>
            {project.appStoreLink
                ? <Link to={{ pathname: `${project.appStoreLink}` }} target='_blank'>
                    <AppStoreContainer>
                        <AppStore />
                    </AppStoreContainer>
                </Link>
                : <CominSoonContainer>
                    {'COMING SOON TO THE APP STORE'}
                </CominSoonContainer>}
            <Desktop>
                <DownArrowContainer onClick={scrollToContent}>
                    <Down width={'45'} />
                </DownArrowContainer>
                <div style={{ height: '45px' }} />
            </Desktop>
        </InfoContainer>
    )
}

export default ProjectInfo
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Image } from 'react-bootstrap'

import {
    phoneContainerHeight,
    transitionTimingMS,
    transitionTimingS,
    projectTitleHeight,
    minWidth,
    maxWidth,
    colors,
    minPhoneContainerHeight,
} from '../../../styles/global'
import { converTitleToUrl } from '../../../constants'
import PhoneMobile from './PhoneMobile'
import PhoneDesktop from './PhoneDesktop'
import loadingImage from '../../../images/shim.png'
import { addAnalytics } from '../../../api'

export const StyledImage = styled(Image)`
    transition: opacity 0.4s ease-in-out;

    @media only screen and (min-width: ${minWidth}) {
        width: auto;
        max-height: calc(${phoneContainerHeight} - ${projectTitleHeight});
        min-height: ${minPhoneContainerHeight};
        cursor: pointer;
    }
    
    @media only screen and (max-width: ${maxWidth}) {
        width: 100%;
        height: auto;
        max-width: 25vw;
    }

    ${props => props.$loading && css`
        animation-duration: 1.2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: linear-gradient(to right, ${props.$bgColor || colors.lightBg} 8%, ${colors.bgColor} 18%, ${props.$bgColor || colors.lightBg} 20%);
        background-size: 2000px 640px;
        border-radius: 30px;
        opacity: 0.4;

        @keyframes placeHolderShimmer{
            0%{
                background-position: -468px 0
            }
            100%{
                background-position: 468px 0
            }
        }
    `}

    @media only screen and (max-width: 650px) {
        max-width: 30vw;
        ${props => props.$loading && css`
            border-radius: 20px;
        `}
    }
`

export const ProjectTitle = styled.div`
    margin-top: 20px;
    
    height: ${projectTitleHeight};
    opacity: 1;
    transition: ${transitionTimingS} ease-in-out;
    color: ${colors.textColor};
    cursor: pointer;

    ${props => props.isSelected && css`
        opacity 0;
    `}

    ${props => props.header && css`
        margin-top: 0;
        margin-bottom: 5px;
        height: auto;
        color: white;
    `}

    ${props => props.hover && css`
        color: black;
    `}
`

export const H1 = styled.h1`
    text-align: center;
    font-size: 21px;
    font-weight: 900;
    line-height: 1.5;
    margin: 0;

    ${props => props.header && css`
        text-align: left;
        font-size: 22px;
        line-height: 1.1;
    `}
`

// prop types
Phone.propTypes = {
    close: PropTypes.bool,
    closeProject: PropTypes.func,
    hoverIndex: PropTypes.number,
    setHoverIndex: PropTypes.func,
    index: PropTypes.number.isRequired,
    isClosing: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    project: PropTypes.object.isRequired,
    projects: PropTypes.array,
    refs: PropTypes.any,
    scroll: PropTypes.func,
    selectedIndex: PropTypes.number,
    setSelectedIndex: PropTypes.func.isRequired,
}

// component
function Phone({
    close,
    closeProject,
    hoverIndex,
    setHoverIndex,
    index,
    isClosing,
    isMobile,
    project,
    projects,
    refs,
    scroll,
    selectedIndex,
    setSelectedIndex,
}) {
    const history = useHistory()
    const [isSelecting, setIsSelecting] = useState(false)

    const selectPhone = () => {
        if (selectedIndex === index) {
            setSelectedIndex(undefined)
        } else {
            setSelectedIndex(index)
            setIsSelecting(true)

            setTimeout(() => {
                scroll(project._id)
            }, 100)
            
            setTimeout(() => {
                setIsSelecting(false)
            }, transitionTimingMS)

            history.push(`/${converTitleToUrl(project.title)}`)

            addAnalytics(`${project.title}_selected`)
        }
    }

    const getSrc = (didLoadImage, isSelected) => {
        if (didLoadImage) {
            if (isSelected) {
                return project.gifSrc || project.imageSrc
            } else {
                return project.imageSrc
            }
        } else {
            return loadingImage
        }
    }

    return (
        <>
            <PhoneDesktop
                getSrc={getSrc}
                index={index}
                hoverIndex={hoverIndex}
                setHoverIndex={setHoverIndex}
                isClosing={isClosing}
                isSelecting={isSelecting}
                project={project}
                selectedIndex={selectedIndex}
                selectPhone={selectPhone} />
            <PhoneMobile
                close={close}
                closeProject={closeProject}
                getSrc={getSrc}
                index={index}
                isMobile={isMobile}
                projects={projects}
                project={project}
                refs={refs}
                selectedIndex={selectedIndex}
                selectPhone={selectPhone} />
        </>
    )
}

export default Phone
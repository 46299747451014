import React from 'react'
import PropTypes from 'prop-types'

export const Chevron = ({ width, height }) => (
    <svg width={width || '23'} height={height || '39'}  viewBox="0 0 23 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.1035 19.373C22.1035 19.0312 21.9814 18.8115 21.7373 18.5918L2.01074 0.476562C1.83984 0.28125 1.5957 0.183594 1.30273 0.183594C0.790039 0.183594 0.350586 0.598633 0.350586 1.16016C0.350586 1.42871 0.448242 1.64844 0.619141 1.81934L19.7109 19.373L0.619141 36.9268C0.448242 37.0977 0.350586 37.293 0.350586 37.5859C0.350586 38.1475 0.790039 38.5381 1.30273 38.5381C1.5957 38.5381 1.83984 38.4404 2.01074 38.2695L21.7373 20.1543C21.9814 19.9346 22.1035 19.6904 22.1035 19.373Z" fill="#000000"/>
    </svg>
)

Chevron.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
}
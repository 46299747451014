import React, { useRef } from 'react'
import PropTypes, { object } from 'prop-types'
import styled, { css } from 'styled-components'
import { isEmpty } from 'lodash'

import Phone from './Phone'
import ProjectInfo from './ProjectInfo'
import { navHeightNum } from '../../../components/Nav'
import {
    GlobalStyle,
    margin,
    Desktop,
    Mobile,
    minPhoneContainerHeight,
    phoneContainerHeight,
    phoneContainerWidth,
    projectTitleHeight,
    colors,
} from '../../../styles/global'
import { Close } from '../../../assets/Close'
import { footerHeight } from '../../../components/Footer'

// styles
const spacerWidth = '2vw'

const HeaderContainer = styled.div`
    position: relative;
    height: calc(${phoneContainerHeight} + ${projectTitleHeight});
    min-height: calc(${minPhoneContainerHeight} + ${projectTitleHeight} + ${footerHeight});
    overflow: hidden;
    margin: 40px 0;
    
    ${props => props.show && css`
        height: ${phoneContainerHeight};
        min-height: ${minPhoneContainerHeight};
    `}
`

const HeaderContentContainer = styled.div`
    width: calc(100vw - ${phoneContainerWidth} - (${margin} * 2) - ${spacerWidth});
    height: calc(${phoneContainerHeight} - ${projectTitleHeight});
    position: absolute;
    left: calc(${margin} + ${phoneContainerWidth} + ${spacerWidth});
    opacity: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    
    ${props => props.show && css`
        opacity: 1;
        transition: opacity 1.2s ease-in-out;
        min-height: ${minPhoneContainerHeight};
    `}
`

const CloseButton = styled.button`
    position: absolute;
    right: 15vw;
    opacity: 0;
    border: 0;

    cursor: pointer;
    background-color: ${colors.lightBg};
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    z-index: 2;

    ${props => props.show && css`
        opacity: 1;
        transition: opacity 1.2s ease-in-out;
    `}
`

// prop types
Header.propTypes = {
    close: PropTypes.bool,
    closeProject: PropTypes.func.isRequired,
    hoverIndex: PropTypes.number,
    setHoverIndex: PropTypes.func,
    isClosing: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    projects: PropTypes.arrayOf(object).isRequired,
    selectedIndex: PropTypes.number,
    setSelectedIndex: PropTypes.func.isRequired,
    scrollToContent: PropTypes.func.isRequired,
}

// component
function Header({
    close,
    closeProject,
    hoverIndex,
    setHoverIndex,
    isClosing,
    isMobile,
    projects,
    selectedIndex,
    setSelectedIndex,
    scrollToContent,
}) {
    // for linking on mobile
    let refs = projects.reduce((acc, value) => {
        acc[value._id] = useRef()
        return acc
    }, {})

    // scroll on mobile when project selected
    const scroll = (id) => {
        // offset top + nav height
        if (isMobile) {
            let ref = refs[id].current
            if (ref !== null) {
                window.scrollTo({
                    top: ref.offsetTop - navHeightNum,
                    left: 0,
                    behavior: 'smooth',
                })
            }
        }
    }

    const renderPhones = () => {
        return projects.map((i, index) => {
            return (
                <Phone
                    key={`${i}-${index}`}
                    close={close}
                    closeProject={closeProject}
                    hoverIndex={hoverIndex}
                    setHoverIndex={setHoverIndex}
                    index={index + 1}
                    isClosing={isClosing}
                    isMobile={isMobile}
                    project={i}
                    projects={projects}
                    refs={refs}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    scroll={scroll} />
            )
        })
    }

    const renderHeaderContent = () => {
        const getProject = projects.filter(p => p.projectIndex === selectedIndex)
        const project = getProject[0]
        if (isEmpty(project)) return
        return (
            <ProjectInfo project={project} scrollToContent={scrollToContent} />
        )
    }

    return (
        <>
            <Desktop>
                <HeaderContainer
                    show={selectedIndex}>
                    <GlobalStyle />
                    <CloseButton
                        show={selectedIndex}
                        type={'button'}
                        onClick={() => {
                            closeProject(false)
                        }}>
                        <Close width={'14'} height={'14'} />
                    </CloseButton>
                    {renderPhones()}
                    <HeaderContentContainer
                        show={selectedIndex}>
                        {renderHeaderContent()}
                    </HeaderContentContainer>
                </HeaderContainer>
            </Desktop>
            <Mobile>
                {renderPhones()}
            </Mobile>
        </>
    )
}

export default Header
import React from 'react'
import PropTypes, { object } from 'prop-types'
import styled, { css } from 'styled-components'
import { isEmpty, orderBy } from 'lodash'

import { projectKeys, layout } from '../../../constants'
import ContentContainer from './ContentContainer'
import { transitionTimingS } from '../../../styles/global'

// styles
const ProjectContentContainer = styled.div`
    transition: ${transitionTimingS};
    opacity: 0;

    ${props => props.show && css`
        opacity: 1;
    `}
`

// prop types
ProjectContent.propTypes = {
    closeProject: PropTypes.func,
    projects: PropTypes.arrayOf(object),
    selectedIndex: PropTypes.number,
}

// component
function ProjectContent({ closeProject, projects, selectedIndex }) {

    const renderContent = () => {
        const getProject = projects.filter(p => p.projectIndex === selectedIndex)
        const project = getProject[0]
        if (isEmpty(project) && isEmpty(project.content)) return
        const sortedContent = orderBy(project.content, [projectKeys.content[0].order], ['asc'])
        return sortedContent.map((content, index) => {
            return (
                <ContentContainer
                    closeProject={closeProject}
                    key={`${index}-${content.order}`}
                    isLast={index === sortedContent.length - 1}
                    left={content.layout === layout.left}
                    right={content.layout === layout.right}
                    center={content.layout === layout.center}
                    content={content} />
            )
        })
    }

    return (
        <ProjectContentContainer show={selectedIndex}>
            {selectedIndex &&
                renderContent()}
        </ProjectContentContainer>
    )
}

export default ProjectContent
export const collections = {
    projects: 'projects'
}

export const converTitleToUrl = (title) => {
    return title.toLowerCase().replace(/\s/g, '')
}

export const layout = {
    left: 'left',
    right: 'right',
    center: 'center',
}

export const projectKeys = {
    _id: '_id', // string
    description: 'description', // string
    imageSrc: 'imageSrc', // string
    projectIndex: 'projectIndex', // number
    title: 'title', // string
    content: [
        {
            description: 'description', // string
            imageSrc: 'imageSrc', // string
            layout: 'layout', // layout options
            order: 'order', // number
            title: 'title', // string
        },
    ]
}

export const routes = {
    projects: '/projects'
}

// copy

export const name = 'Jen O\'Dowd'
export const role = 'iOS Developer'
export const email = 'jenodowd@me.com'
export const subject = 'Hello'
export const linkedin = 'https://www.linkedin.com/in/jenniferodowd/'
export const github = 'https://github.com/jenodowd'
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { maxWidth, colors } from '../styles/global'
import { Email } from '../assets/Email'
import { LinkedIn } from '../assets/LinkedIn'
import { Github } from '../assets/Github'
import { addAnalytics } from '../api'
import { name, email, subject, linkedin, github } from '../constants'

export const footerHeight = '75px'

// styles
const FooterContainer = styled.div`
    height: ${footerHeight};
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 10vw;
    ${props => props.$selectedIndex && css`
        background-color: ${colors.bgColor};
    `}
    @media only screen and (max-width: ${maxWidth}) {
        padding: 0 5vw;
        background-color: ${colors.bgColor};
    }
`

const CopyrightWrapper = styled.div`
    font-size: 14px;
    font-weight: 400;
    margin-right: auto;
`

const IconLink = styled(Link)`
    margin-left: 15px;
    :hover {
        opacity: 0.6;
    }
`

const IconWrapper = styled.div`
    margin-left: 15px;
    cursor: pointer;
    :hover {
        opacity: 0.6;
    }
`

const IconsWrapper = styled.div`
    display: flex;
`

// prop types
Footer.propTypes = {
    selectedIndex: PropTypes.number,
}

// component
function Footer({ selectedIndex }) {

    return (
        <FooterContainer $selectedIndex={selectedIndex}>
            <CopyrightWrapper>
                {`© ${new Date().getFullYear()} ${name}`}
            </CopyrightWrapper>
            <IconsWrapper>
                <IconWrapper>
                    <a
                        href={`mailto:${email}?subject=${subject}`}
                        onClick={() => { addAnalytics('email_clicked') }}>
                        <Email width={'30'} height={'30'}/>
                    </a>
                </IconWrapper>
                <IconLink
                    to={{ pathname: linkedin }}
                    onClick={() => { addAnalytics('linkedin_clicked') }}
                    target='_blank'>
                    <LinkedIn width={'30'} height={'30'}/>
                </IconLink>
                <IconLink
                    to={{ pathname: github }}
                    onClick={() => { addAnalytics('github_clicked') }}
                    target='_blank'>
                    <Github width={'30'} height={'30'}/>
                </IconLink>
            </IconsWrapper>
        </FooterContainer>
    )
}

export default Footer
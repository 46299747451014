import React from 'react'
import PropTypes from 'prop-types'

export const Logo = ({ width, height }) => (
    <svg width={width || '622'} height={height || '75'} viewBox="0 0 622 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.40002 64.1C3.80002 65.1 5.63336 65.9667 7.90002 66.7C10.2334 67.4333 12.9 67.8 15.9 67.8C19.3667 67.8 22.1667 67.2333 24.3 66.1C26.4334 64.9 28.0667 63.1333 29.2 60.8C30.3334 58.4 31.1 55.4333 31.5 51.9C31.9 48.3667 32.1 44.2333 32.1 39.5V2H39.1V38.1C39.1 43.4333 38.8334 48.3 38.3 52.7C37.7667 57.1 36.7334 60.9 35.2 64.1C33.6667 67.3 31.3667 69.7667 28.3 71.5C25.2334 73.2333 21.1667 74.1 16.1 74.1C12.8334 74.1 9.93336 73.7333 7.40002 73C4.86669 72.2667 2.53336 71.1667 0.400024 69.7L2.40002 64.1Z" fill="black" />
        <path d="M103.745 66.8V73H56.0446V2H102.845V8.2H63.0446V33.8H97.7446V39.7H63.0446V66.8H103.745Z" fill="black" />
        <path d="M123.982 14.9V73H116.982V2H122.782L169.382 61.2V2.1H176.382V73H169.882L123.982 14.9Z" fill="black" />
        <path d="M248.316 73.5C243.316 73.5 238.783 72.5 234.716 70.5C230.65 68.5 227.15 65.8 224.216 62.4C221.283 58.9333 219.016 55.0667 217.416 50.8C215.816 46.4667 215.016 42.0333 215.016 37.5C215.016 32.7667 215.85 28.2667 217.516 24C219.183 19.6667 221.516 15.8333 224.516 12.5C227.583 9.1 231.15 6.43333 235.216 4.5C239.283 2.5 243.683 1.5 248.416 1.5C253.416 1.5 257.95 2.53333 262.016 4.6C266.083 6.66666 269.55 9.43333 272.416 12.9C275.35 16.3667 277.616 20.2333 279.216 24.5C280.816 28.7667 281.616 33.1333 281.616 37.6C281.616 42.3333 280.783 46.8667 279.116 51.2C277.45 55.4667 275.116 59.3 272.116 62.7C269.116 66.0333 265.583 68.6667 261.516 70.6C257.45 72.5333 253.05 73.5 248.316 73.5ZM222.016 37.5C222.016 41.3667 222.65 45.1 223.916 48.7C225.183 52.2333 226.983 55.4 229.316 58.2C231.65 60.9333 234.416 63.1333 237.616 64.8C240.883 66.4 244.45 67.2 248.316 67.2C252.383 67.2 256.016 66.3667 259.216 64.7C262.483 62.9667 265.25 60.7 267.516 57.9C269.85 55.0333 271.616 51.8333 272.816 48.3C274.016 44.7667 274.616 41.1667 274.616 37.5C274.616 33.6333 273.983 29.9333 272.716 26.4C271.45 22.8667 269.616 19.7 267.216 16.9C264.883 14.1 262.116 11.9 258.916 10.3C255.716 8.7 252.183 7.9 248.316 7.9C244.316 7.9 240.683 8.73333 237.416 10.4C234.216 12.0667 231.45 14.3333 229.116 17.2C226.85 20 225.083 23.1667 223.816 26.7C222.616 30.2333 222.016 33.8333 222.016 37.5Z" fill="black" />
        <path d="M293.014 20.9L294.714 10.2H292.314V0H298.914V10.2L296.414 20.9H293.014Z" fill="black" />
        <path d="M314.857 73V2H338.957C346.49 2 352.757 3.6 357.757 6.8C362.824 9.93333 366.624 14.2 369.157 19.6C371.69 24.9333 372.957 30.8667 372.957 37.4C372.957 44.6 371.557 50.8667 368.757 56.2C366.024 61.5333 362.09 65.6667 356.957 68.6C351.89 71.5333 345.89 73 338.957 73H314.857ZM365.957 37.4C365.957 31.7333 364.89 26.7 362.757 22.3C360.69 17.9 357.657 14.4667 353.657 12C349.657 9.46667 344.757 8.2 338.957 8.2H321.857V66.8H338.957C344.824 66.8 349.757 65.5 353.757 62.9C357.757 60.3 360.79 56.8 362.857 52.4C364.924 47.9333 365.957 42.9333 365.957 37.4Z" fill="black" />
        <path d="M414.941 73.5C409.941 73.5 405.408 72.5 401.341 70.5C397.275 68.5 393.775 65.8 390.841 62.4C387.908 58.9333 385.641 55.0667 384.041 50.8C382.441 46.4667 381.641 42.0333 381.641 37.5C381.641 32.7667 382.475 28.2667 384.141 24C385.808 19.6667 388.141 15.8333 391.141 12.5C394.208 9.1 397.775 6.43333 401.841 4.5C405.908 2.5 410.308 1.5 415.041 1.5C420.041 1.5 424.575 2.53333 428.641 4.6C432.708 6.66666 436.175 9.43333 439.041 12.9C441.975 16.3667 444.241 20.2333 445.841 24.5C447.441 28.7667 448.241 33.1333 448.241 37.6C448.241 42.3333 447.408 46.8667 445.741 51.2C444.075 55.4667 441.741 59.3 438.741 62.7C435.741 66.0333 432.208 68.6667 428.141 70.6C424.075 72.5333 419.675 73.5 414.941 73.5ZM388.641 37.5C388.641 41.3667 389.275 45.1 390.541 48.7C391.808 52.2333 393.608 55.4 395.941 58.2C398.275 60.9333 401.041 63.1333 404.241 64.8C407.508 66.4 411.075 67.2 414.941 67.2C419.008 67.2 422.641 66.3667 425.841 64.7C429.108 62.9667 431.875 60.7 434.141 57.9C436.475 55.0333 438.241 51.8333 439.441 48.3C440.641 44.7667 441.241 41.1667 441.241 37.5C441.241 33.6333 440.608 29.9333 439.341 26.4C438.075 22.8667 436.241 19.7 433.841 16.9C431.508 14.1 428.741 11.9 425.541 10.3C422.341 8.7 418.808 7.9 414.941 7.9C410.941 7.9 407.308 8.73333 404.041 10.4C400.841 12.0667 398.075 14.3333 395.741 17.2C393.475 20 391.708 23.1667 390.441 26.7C389.241 30.2333 388.641 33.8333 388.641 37.5Z" fill="black" />
        <path d="M485.502 2.5H492.102L502.102 27.7L512.202 2.5H518.902L506.502 32.8L520.102 64.9L545.802 2H553.402L523.302 73H517.102L502.202 37.9L487.202 73H481.102L451.102 2H458.602L484.402 64.9L497.902 32.8L485.502 2.5Z" fill="black" />
        <path d="M563.392 73V2H587.492C595.026 2 601.292 3.6 606.292 6.8C611.359 9.93333 615.159 14.2 617.692 19.6C620.226 24.9333 621.492 30.8667 621.492 37.4C621.492 44.6 620.092 50.8667 617.292 56.2C614.559 61.5333 610.626 65.6667 605.492 68.6C600.426 71.5333 594.426 73 587.492 73H563.392ZM614.492 37.4C614.492 31.7333 613.426 26.7 611.292 22.3C609.226 17.9 606.192 14.4667 602.192 12C598.192 9.46667 593.292 8.2 587.492 8.2H570.392V66.8H587.492C593.359 66.8 598.292 65.5 602.292 62.9C606.292 60.3 609.326 56.8 611.392 52.4C613.459 47.9333 614.492 42.9333 614.492 37.4Z" fill="black" />
    </svg>
)

Logo.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
}

import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { orderBy, isEmpty } from 'lodash'
// for safari
import smoothscroll from 'smoothscroll-polyfill'

import { fetchAllDocs, addAnalytics } from '../../api'
import { collections, converTitleToUrl, projectKeys, name, role } from '../../constants'
import { transitionTimingMS, Desktop, minWidthNum, minWidth, minPhoneContainerHeight } from '../../styles/global'
import Footer, { footerHeight } from '../../components/Footer'
import Nav, { navHeight, navHeightNum } from '../../components/Nav'
import Header from './components/Header'
import ProjectContent from './components/ProjectContent'

const ContentContainer = styled.div`
    @media only screen and (min-width: ${minWidth}) {
        height: calc(100vh - ${footerHeight});
        min-height: calc(${minPhoneContainerHeight} + ${navHeight} + ${footerHeight} + 100px);
        ${props => props.selectedIndex && css`
            height: auto;
        `}
    }
`

// component
function Home() {
    const { projectName } = useParams()
    const [close, setClose] = useState(false)
    const [isClosing, setClosing] = useState(false)
    const [hoverIndex, setHoverIndex] = useState(undefined)
    const [projects, setProjects] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(undefined)
    const [width, setWidth] = useState(window.innerWidth)

    const contentRef = useRef(null)
    const history = useHistory()
    let isMobile = (width <= minWidthNum)

    useEffect(() => {
        // for scroll on safari
        smoothscroll.polyfill()
        addAnalytics('page_loaded')
        // get all data
        getData()
    }, [])

    // to find if isMobile
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    // set document title
    useEffect(() => {
        if (selectedIndex) {
            let project = projects[selectedIndex - 1]
            if (project) {
                document.title = `${name} | ${project.title}`
            }
        } else {
            document.title = `${name} | ${role}`
        }
    }, [selectedIndex])

    const getData = async () => {
        const { data } = await fetchAllDocs(collections.projects)
        if (data) {
            const sortedData = orderBy(data, [projectKeys.projectIndex], ['asc'])
            setProjects(sortedData)
            getCurrentProject(sortedData)
        }
    }

    // if on route, set current project
    const getCurrentProject = (projects) => {
        if (!isEmpty(projects)) {
            let project = projects.filter(p => converTitleToUrl(p.title) === projectName)
            if (!isEmpty(project)) {
                setSelectedIndex(project[0].projectIndex)
            }
        }
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }

    const closeProject = (isLink = false, isNav = false) => {
        setSelectedIndex(undefined)
        // for closing animation
        setClosing(true)
        setTimeout(() => setClosing(false), transitionTimingMS)
        // reroute if not a link
        if (!isLink) {
            history.push('/')
        }
        // close all tabs on mobile if clicked on nav link
        if (isNav) {
            setClose(true)
            setTimeout(() => setClose(false), transitionTimingMS)
        }
    }

    // scroll to content on desktop arrow click
    const scrollToContent = () => {
        window.scrollTo({
            top: contentRef.current.offsetTop - navHeightNum,
            left: 0,
            behavior: 'smooth',
        })
    }

    return (
        <>
            <ContentContainer
                selectedIndex={selectedIndex}>
                <Nav
                    closeProject={closeProject} />
                <Header
                    close={close}
                    closeProject={closeProject}
                    hoverIndex={hoverIndex}
                    isClosing={isClosing}
                    isMobile={isMobile}
                    projects={projects}
                    scrollToContent={scrollToContent}
                    selectedIndex={selectedIndex}
                    setHoverIndex={setHoverIndex}
                    setSelectedIndex={setSelectedIndex} />
                <Desktop
                    ref={contentRef}>
                    <ProjectContent
                        closeProject={closeProject}
                        projects={projects}
                        selectedIndex={selectedIndex} />
                </Desktop>
            </ContentContainer>
            <Footer
                selectedIndex={selectedIndex} />
        </>
    )
}

export default Home
import React from 'react'
import PropTypes from 'prop-types'

export const Down = ({ width, height }) => (
    <svg width={width || '39'} height={height || '23'} viewBox="0 0 39 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.4395 22.7012C19.7812 22.7012 20.0742 22.5059 20.3428 22.2373L38.3359 2.55957C38.5312 2.33984 38.6533 2.16895 38.6533 1.87598C38.6533 1.33887 38.2383 0.923828 37.7012 0.923828C37.4326 0.923828 37.1641 0.99707 36.9932 1.16797L19.4639 20.333L1.86133 1.16797C1.71484 0.99707 1.44629 0.923828 1.17773 0.923828C0.640625 0.923828 0.225586 1.33887 0.225586 1.87598C0.225586 2.16895 0.37207 2.36426 0.567383 2.58398L18.5361 22.2617C18.8047 22.5303 19.0732 22.7012 19.4395 22.7012Z" fill="#E78E76"/>
    </svg>
)

Down.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
}
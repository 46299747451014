import styled, { createGlobalStyle } from 'styled-components'

export const minWidthNum = 950
export const minWidth = `${minWidthNum}px`
export const maxWidth = `calc(${minWidth} - 1px)`

export const margin = '15vw'

export const phoneContainerHeight = '65vh'
export const minPhoneContainerHeight = '425px'
export const phoneContainerWidth = '25vw'
export const marginWidth = '12vw'

export const transitionTimingS = '0.6s'
export const transitionTimingMS = 600

export const projectTitleHeight = '50px'

export const colors = {
    bgColor: 'white',
    textColor: '#818181',
    highlightColor: '#e78e76',
    lightBg: '#efefef',
}

export const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        line-height: 1.4;
        background-color: ${colors.bgColor};
        scroll-behavior: smooth;
        min-width: 290px;

        br {
            content: "";
            margin: 2em;
            display: block;
            font-size: 24%;
        }
    }
`

export const Desktop = styled.div`
    display: none;
    @media only screen and (min-width: ${minWidth}) {
        display: unset;
    }
`

export const Mobile = styled.div`
    display: none;
    @media only screen and (max-width: ${maxWidth}) {
        display: unset;
    }
`

export const Backdrop = styled.div`
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    position: fixed;
`
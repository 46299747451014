import React from 'react'
import PropTypes from 'prop-types'

export const Github = ({ width, height }) => (
    <svg width={width || '24'} height={height || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM14.218 18.616C13.864 18.685 13.75 18.467 13.75 18.28V16.359C13.75 15.706 13.521 15.28 13.269 15.063C14.829 14.89 16.467 14.298 16.467 11.609C16.467 10.844 16.194 10.22 15.746 9.73C15.818 9.553 16.058 8.841 15.677 7.877C15.677 7.877 15.09 7.689 13.754 8.594C13.193 8.44 12.595 8.363 12 8.36C11.405 8.363 10.807 8.44 10.247 8.595C8.91 7.69 8.322 7.878 8.322 7.878C7.943 8.842 8.182 9.554 8.255 9.73C7.807 10.22 7.533 10.844 7.533 11.609C7.533 14.291 9.167 14.891 10.722 15.068C10.522 15.243 10.341 15.551 10.278 16.004C9.878 16.183 8.865 16.492 8.241 15.422C8.241 15.422 7.871 14.75 7.168 14.7C7.168 14.7 6.485 14.691 7.12 15.126C7.12 15.126 7.58 15.341 7.897 16.15C7.897 16.15 8.302 17.4 10.25 16.976V18.279C10.25 18.464 10.137 18.681 9.788 18.616C7.006 17.691 5 15.067 5 11.975C5 8.108 8.135 4.975 12 4.975C15.865 4.975 19 8.108 19 11.975C19 15.066 16.997 17.69 14.218 18.616V18.616Z" fill="#000000"/>
    </svg>
)

Github.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
}
import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../styles/global'

export const Close = ({ width, height, fill }) => (
    <svg width={width || '39'} height={height || '39'} viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.860385 35.047C0.0303066 35.8771 0.00589252 37.2931 0.860385 38.1232C1.71488 38.9533 3.13089 38.9533 3.96097 38.1232L19.586 22.4982L35.211 38.1232C36.041 38.9533 37.4815 38.9777 38.3116 38.1232C39.1416 37.2687 39.1416 35.8771 38.3116 35.047L22.6866 19.3976L38.3116 3.77262C39.1416 2.94254 39.166 1.52652 38.3116 0.696443C37.4571 -0.158049 36.041 -0.158049 35.211 0.696443L19.586 16.3214L3.96097 0.696443C3.13089 -0.158049 1.69046 -0.182463 0.860385 0.696443C0.0303066 1.55094 0.0303066 2.94254 0.860385 3.77262L16.4854 19.3976L0.860385 35.047Z" fill={fill ? colors.highlightColor : '#000000'} />
    </svg>
)

Close.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    fill: PropTypes.bool,
}
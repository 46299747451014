import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import {
    margin,
    marginWidth,
    phoneContainerWidth,
    transitionTimingS,
    Desktop,
} from '../../../styles/global'
import { H1, ProjectTitle, StyledImage } from './Phone'

const getLeftHomePosition = (index) => {
    switch (index) {
        case 1:
            return css`left: ${marginWidth};`
        case 2:
            return css`left: calc(50vw - (${phoneContainerWidth} / 2));`
        default:
            return css`left: calc(100vw - (${phoneContainerWidth} + ${marginWidth}));`
    }
}

const getLeftSelectedPosition = (selectedIndex, index) => {
    const goRight = css`
        left: calc(100vw + ${phoneContainerWidth});
    `
    const goLeft = css`
        left: -${phoneContainerWidth};
    `
    switch (selectedIndex) {
        case 1:
            return goRight
        case 2:
            if (index === 1) {
                return goLeft
            } else {
                return goRight
            }
        default:
            return goLeft
    }
}

const PhoneContainer = styled.div`
    width: ${phoneContainerWidth};
    position: absolute;
    top: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;

    ${props => props.isClosing && css`
        transition: ${transitionTimingS} ease-in-out;
    `}

    ${props => props.index && css`
        ${getLeftHomePosition(props.index)}
    `}

    // another item is selected
    ${props => props.selectedIndex && (props.selectedIndex !== props.index) && css`
        transition: ${transitionTimingS} ease-in-out;
        ${getLeftSelectedPosition(props.selectedIndex, props.index)}
        opacity: 0;
    `}

    // item is selected
    ${props => (props.selectedIndex === props.index) && css`
        ${props.isSelecting && css`
            transition: ${transitionTimingS} ease-in-out;
        `}
        left: ${margin};
    `}
`

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const H2 = styled.h2`
    text-align: center;
    font-size: 20px;
    font-weight: 400;
`

// prop types
PhoneDesktop.propTypes = {
    getSrc: PropTypes.func,
    hoverIndex: PropTypes.number,
    setHoverIndex: PropTypes.func,
    index: PropTypes.number.isRequired,
    isClosing: PropTypes.bool.isRequired,
    isSelecting: PropTypes.bool,
    project: PropTypes.object.isRequired,
    selectedIndex: PropTypes.number,
    selectPhone: PropTypes.func,
}

// component
function PhoneDesktop({
    getSrc,
    hoverIndex,
    setHoverIndex,
    index,
    isClosing,
    isSelecting,
    project,
    selectedIndex,
    selectPhone,
}) {
    const [didLoadImage, setDidLoadImage] = useState(false)

    return (
        <Desktop>
            <PhoneContainer
                index={index}
                isClosing={isClosing}
                isSelecting={isSelecting}
                onClick={() => {
                    if (!selectedIndex) {
                        selectPhone()
                    }
                }}
                selectedIndex={selectedIndex}>
                <FlexContainer
                    onMouseOver={() => setHoverIndex(index)}
                    onMouseOut={() => setHoverIndex(undefined)}>
                    <StyledImage
                        $loading={!didLoadImage}
                        onLoad={() => {
                            setTimeout(() => setDidLoadImage(true), 500)
                        }}
                        src={getSrc(didLoadImage, selectedIndex === index)} />
                    <ProjectTitle
                        hover={hoverIndex === index}
                        isSelected={selectedIndex === index}>
                        <H1>
                            {project.title.toUpperCase()}
                        </H1>
                        <H2>
                            {project.blurb}
                        </H2>
                    </ProjectTitle>
                </FlexContainer>
            </PhoneContainer>
        </Desktop>
    )
}

export default PhoneDesktop
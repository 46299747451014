import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'

import Home from './views/home/Home'
import { routes } from './constants'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact={true}
          path='/'
          render={() => {
            return (
              <Home />
            )
          }}
        />
        <Route
          exact={true}
          path={routes.projects}
          render={() => {
            return (
              <Home />
            )
          }}
        />
        <Route
          exact={true}
          path='/:projectName'
          render={() => {
            return (
              <Home />
            )
          }}
        />
      </Switch>
    </BrowserRouter>
  )
}

export default App

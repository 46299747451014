// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore, getDocs, collection } from 'firebase/firestore'
import { getAnalytics, logEvent } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCTzX8JC4IkMFHEmFr43aS3EOfocKmhEek',
  authDomain: 'portfolio-7bbbc.firebaseapp.com',
  projectId: 'portfolio-7bbbc',
  storageBucket: 'portfolio-7bbbc.appspot.com',
  messagingSenderId: '974614889442',
  appId: '1:974614889442:web:29b306710787742b652fbb',
  measurementId: 'G-YJ4G8CSNHP'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const analytics = getAnalytics()

export const fetchAllDocs = async (dbCollection) => {
    try {
        const querySnapshot = await getDocs(collection(db, dbCollection))
        const res = []
        querySnapshot.forEach((doc) => {
            res.push(doc.data())
        })
        return { data: res }
    } catch(err) {
        return { error: err }
    }
}

export const addAnalytics = (eventName) => {
    logEvent(analytics, eventName)
}
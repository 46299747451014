import React from 'react'
import { useHistory } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Desktop, margin, colors, maxWidth } from '../../../styles/global'

// styles
const ProjectContentContainer = styled.div`
    padding: 75px ${margin};
    background-color: white;

    ${props => props.left && css`
        display: flex;
        align-items: center;
    `}
    ${props => props.right && css`
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
    `}
    ${props => props.addBgColor && css`
        background-color: ${colors.lightBg};
    `}

    @media (max-width: ${maxWidth}) {
        display: flex;
        flex-direction: column;
        padding: 25px;
    }
`

const ImageContainer = styled.div`
    display: flex;

    ${props => props.center && css`
        justify-content: center;
    `}
`

const StyledImage = styled(Image)`
    width: auto;
    max-height: 55vh;

    @media (max-width: ${maxWidth}) {
        max-width: 70vw;
        height: 100%;
        margin: auto;
    }
`

const textPadding = '5vw'

const TextContainer = styled.div`
    width: 100%;
    max-width: 100%;

    ${props => props.center && css`
        padding-top: ${textPadding};
        max-width: 45vw;
    `}
    ${props => props.left && css`
        padding-left: ${textPadding};
    `}
    ${props => props.right && css`
        padding-right: ${textPadding};
    `}

    @media (max-width: ${maxWidth}) {
        padding: 0;
        padding-top: 50px;
        max-width: 100%;
    }
`

const TitleWrapper = styled.h3`
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;

    @media (max-width: ${maxWidth}) {
        font-size: 24px;
    }
`

const DescWrapper = styled.p`
    font-size: 20px;
    margin-top: 10px;
    white-space: pre-wrap;

    @media (max-width: ${maxWidth}) {
        font-size: 18px;
    }
`

const BackButtonContainer = styled.div`
    padding: 40px 10vw;

    ${props => props.addBgColor && css`
        background-color: ${colors.lightBg};
    `}
`

const Button = styled.button`
    color: black;
    font-size: 15px;
    font-weight: 600;
    border: 0;
    :hover {
        color: ${colors.textColor};
    }
`

// prop types
ContentContainer.propTypes = {
    center: PropTypes.bool,
    closeProject: PropTypes.func,
    content: PropTypes.object,
    isLast: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
}

// component
function ContentContainer({
    center,
    closeProject,
    content,
    isLast,
    left,
    right
}) {

    const history = useHistory()

    // set html text
    const createText = () => {
        return { __html: content.description }
    }

    return (
        <>
            <ProjectContentContainer
                addBgColor={content.order % 2 !== 0}
                isLast={isLast}
                left={left}
                right={right} >
                <ImageContainer
                    center={center}>
                    <StyledImage
                        src={content.imageSrc} />
                </ImageContainer>
                <TextContainer
                    center={center}
                    left={left}
                    right={right}>
                    <TitleWrapper>
                        {content.title}
                    </TitleWrapper>
                    <DescWrapper
                        dangerouslySetInnerHTML={createText()} />
                </TextContainer>
            </ProjectContentContainer>
            {isLast &&
                <Desktop>
                    <BackButtonContainer
                        addBgColor={content.order % 2 !== 0}>
                        <Button
                            type={'button'}
                            onClick={() => {
                                closeProject(true)
                                history.goBack()
                            }}>
                            {'BACK TO PROJECTS'}
                        </Button>
                    </BackButtonContainer>
                </Desktop>}
        </>
    )
}

export default ContentContainer